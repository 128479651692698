/*global Component, wrapPost */
class reportComponent extends Component {

    static name() {
        return "reportComponent";
    }

    static componentName() {
        return "reportComponent";
    }

    getProps() {
        return ['reportName'];
    }

    data() {
        return {
            params:{},
            loading:false,
            report:null
        };
    }

    getComputed() {
        return {
            reportTables:function () {
                if(this.report)
                    return Object.keys(this.report);
                return [];
            }
        };
    }

    getMethods() {
        return {
            getReportName:this.getReportName,
            runReport:this.runReport,
            dataURIToBlob:this.dataURIToBlob,
            downloadDataAsFile:this.downloadDataAsFile,
            exportReport:this.exportReport,
        };
    }

    getReportName(){
        let splitReportName = this.reportName.split("_");
        return splitReportName[0];
    }

    runReport(parameters){
        let self = this;
        return new Promise((resolve, reject) => {
            this.loading = true;
            self.report = null;
            wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/runReport`, {
                reportName: self.getReportName(),
                params: parameters,
                recordOrigin:"EcommerceSettings",
                recordField:"ReportRow"
            })
                .then(function (response) {
                    self.report = Object.freeze(response.data.report);
                    //self.report_response = Object.freeze(response.data);
                    self.loading = false;
                    resolve(true);
                })
                .catch(function (error) {
                    self.loading = false;
                    reject(false);
                });
        });
    }

    dataURIToBlob(b64Data, contentType = '', sliceSize = 512) {
        contentType = b64Data.split(',')[0];
        let byteCharacters = atob(b64Data.split(',')[1]);
        function decode_utf8(s) {
            return decodeURIComponent(escape(s));
        }
        byteCharacters = decode_utf8(byteCharacters);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, {type: contentType});
        return blob;
    }

    downloadDataAsFile(filename, data, type) {
        let utf8_blob = this.dataURIToBlob(data,type);
        if(window.navigator.msSaveOrOpenBlob) {
            //console.log("If TEST",type,blob);
            window.navigator.msSaveBlob(utf8_blob, filename);
        }
        else{
            //console.log("else TEST",type,blob);
            let elem = window.document.createElement('a');
            let href = window.URL.createObjectURL(utf8_blob);
            elem.href = href;
            elem.download = filename;
            document.body.appendChild(elem);
            elem.click();
            document.body.removeChild(elem);
            window.URL.revokeObjectURL(href);
        }
    }

    exportReport(parameters){
        let self = this;
        return new Promise((resolve, reject) => {
            this.loading = true;
            wrapPost(`/${this.$store.getters.getSetting.PathURL}/api/exportReport`, {
                reportName: self.reportName,
                recordOrigin:"EcommerceSettings",
                recordField:"ReportRow",
                params: parameters
            })
                .then(function (response) {
                    self.loading = false;
                    self.downloadDataAsFile(response.data.filename,response.data.reportData,'text/excel;encoding:UTF-8');
                    resolve(true);
                })
                .catch(function (error) {
                    self.loading = false;
                    reject(false);
                });
        });
    }


    getTemplate() {
        return `<div class="row">
                 <slot name="parameters" :runAction="runReport" :exportAction="exportReport" :params="params" :loading="loading"></slot>
                 <template v-if="loading" >
                     <div class="row justify-content-center align-items-center profile-loading">
                          <div class="col-6 text-center">
                              <img class="img-fluid" src="../static/ecommerce/img/lg-spinner-gif.gif" >
                              <p class="font-weight-bold">{{tr('Loading Report Data')}}</p>
                          </div>
                      </div>
                 </template>
                 <template v-else>
                     <div :key="'reportRenderContainer-'+getReportName()" id="genericReportView" :ref="'reportRenderContainer-'+reportName" class="col-12 mt-2"  style="overflow-x: auto;">
                          <template v-if="reportTables.length>0" v-for="table in reportTables">
                                <reportTableView :table="Object.freeze(report[table])" :key="reportName+table" :tablename="table" :reportName="reportName" ></reportTableView>
                          </template>
                     </div>
                 </template>
             </div>`;
    }
}

reportComponent.registerComponent();